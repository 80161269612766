<template>
  <div class="page">商场管理首页</div>
</template>

<script setup>
import { inject, onMounted } from 'vue'
import { getCache, updateCache } from '@/provider/cache'
import { isMarketPicked } from '@/provider/account'

const { router } = inject('global')

onMounted(() => {
  if (!isMarketPicked.value) {
    router.push('/account/pick')
  } else {
    router.push('/home')
  }
})
</script>

<style lang="less" scoped>
.page {
  width: 100%;
}
</style>
